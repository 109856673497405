import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { InfiniteGridComponent } from 'src/app/ui-library/component/infinite-grid/infinite-grid.component';
import { EscapeGameService } from 'src/app/_services/escape-game.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { GameWhiteListDialogComponent } from './game-white-list-dialog.component';

@Component({
  selector: 'app-game-white-list',
  templateUrl: './game-white-list.component.html',
})
export class GameWhiteListComponent extends InfiniteGridComponent implements OnInit {
  
    constructor(
        private modalService: ModalService,
        public global: Global,
        private toastr: ToastrService,
        private datePipe: DatePipe,
        private escapeGameService: EscapeGameService,
        private nzModalService: NzModalService

    ) {
      super(global)
     }
  
    ngOnInit(){
      this.setColumnDefs();
    }
  
    ngAfterViewInit(): void {
      this.setDatasource((prop)=>this.escapeGameService.getGameWhiteList(prop))
    }
  
    formatDateTime(params){
      return this.datePipe.transform(params.value, 'short')
    }
  
    
    setColumnDefs(){
      this.columnDefs = [
        { headerName: 'User Id', field: 'userId'},
        { headerName: 'Email', field: 'email'},
        { headerName: 'Action', sortable:false,
          minWidth: 90,
          cellRenderer: params => {
            return ` 
            <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
            <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
              case 'delete':
                this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                break;
              case 'moreDetail':
                this.modalService.showDetail(params.data, 'Game White List Detail')
                break;
            }
          },
      },
    ];
    }
  
    delete(id){
      this.escapeGameService.deleteWhiteList(id).subscribe(res=>{
        this.global.appEvent.next({ msg: 'modalService.close', para: null });
        this.gridApi.purgeInfiniteCache();
        this.toastr.success('Data Deleted Successfully...!', 'Success');
      } )
    }
  
    addWhiteList(){
        this.nzModalService.create({
            nzContent: GameWhiteListDialogComponent,
            nzWidth: '700px',
            nzFooter: null,
            nzTitle: 'Create White List',
            nzOnOk: ()=> this.gridApi.purgeInfiniteCache()
        })
      }

  }
  