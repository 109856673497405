import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-whitelist-wrapper',
  templateUrl: './whitelist-wrapper.component.html',
})
export class WhitelistWrapperComponent implements OnInit {
    currentUrl: string
    constructor(
        private router: Router,
    ) {
        this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
            ).subscribe((event: any) => {
                this.currentUrl = event.url
            });
    }

    ngOnInit(): void {
        this.router.navigateByUrl('/whitelist/partner')
    }


}
