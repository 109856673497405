<div *ngIf="formGroup" class="ae-game-wrapper">
    <form  nz-row [nzGutter]="16" nz-form [formGroup]="formGroup" [nzLayout]="'vertical'">
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Date Range'">
            <nz-range-picker [nzShowTime]="true" formControlName="_date" (ngModelChange)="setDateRange($event)"></nz-range-picker>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Cool Down Interval Type'">
            <nz-select style="width: 100%;"  formControlName="coolDownIntervalType">
                <nz-option *ngFor="let item of IntervalType" [nzValue]="item" [nzLabel]="item"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Description'">
            <input  formControlName="description" nz-input
        />
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="4" [nzLg]="12" [nzXs]="24" [label]="'Allow Rescue'">
            <nz-switch formControlName="isAllowRescue"></nz-switch>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="4" [nzLg]="12" [nzXs]="24" [label]="'Scale'">
            <nz-input-number formControlName="scale" nzMin="0" nzStep="0.01" [nzPrecision]="2"></nz-input-number>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Thumbnail'">
            <app-mit-uploader [formCtrl]="formGroup.get('thumbnail')" [showPreviewImage]="true" [accept]="'image/*'"></app-mit-uploader>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Pop Pic'">
            <app-mit-uploader [formCtrl]="formGroup.get('popPic')" [showPreviewImage]="true" [accept]="'*'"></app-mit-uploader>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Video'">
            <app-mit-uploader [formCtrl]="formGroup.get('videoUrl')" [showPreviewImage]="false" [accept]="'video/*'"></app-mit-uploader>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Tutorial Setting Json'">
            <app-mit-uploader [formCtrl]="formGroup.get('tutorialSettingJsonUrl')" [showPreviewImage]="false" [accept]="'*'"></app-mit-uploader>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'puzzle Setting'">
            <app-mit-uploader [formCtrl]="formGroup.get('puzzleSettingUrl')" [showPreviewImage]="false" [accept]="'*'"></app-mit-uploader>
        </mit-nz-form-item>
        <nz-divider></nz-divider>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Asset Category'">
            <nz-select style="width: 100%;"  formControlName="assetCategoryId" (ngModelChange)="setAssetSource($event)">
                <nz-option *ngFor="let item of categorySource" [nzValue]="item.id" [nzLabel]="item.multilingualNames['en-US']"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Keys Asset'">
            <nz-select style="width: 100%;"  formControlName="keysAssetId">
                <nz-option *ngFor="let item of assetSource" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Portal Asset'">
            <nz-select style="width: 100%;"  formControlName="portalAssetId">
                <nz-option *ngFor="let item of assetSource" [nzValue]="item.id" [nzLabel]="item.name"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <nz-divider></nz-divider>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Reward Of All Keys'">
            <nz-select style="width: 100%;"  formControlName="mmitaRewardIdOfAllKeys">
                <nz-option *ngFor="let item of rewardSource" [nzValue]="item.id" [nzLabel]="item.description"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <mit-nz-form-item nz-col [nzXl]="8" [nzLg]="12" [nzXs]="24" [label]="'Reward Of All Levels'">
            <nz-select style="width: 100%;"  formControlName="mmitaRewardIdOfAllLevels">
                <nz-option *ngFor="let item of rewardSource" [nzValue]="item.id" [nzLabel]="item.description"></nz-option>
            </nz-select>
        </mit-nz-form-item>
        <nz-divider></nz-divider>
    
      </form>
    <nz-table  #editRowTable nzBordered [nzData]="listOfData">
        <thead>
        <tr>
            <th nzWidth="90px">
                    Level 
                    <i class="fas fa-fw fa-plus text-primary" (click)="addRow()" style=" cursor: pointer;"></i>
            </th>
            <th nzWidth="400px">Description</th>
            <th nzWidth="100px">Setting Json Urls</th>
            <th nzWidth="200px">Reward Of Keys</th>
            <th nzWidth="200px">Reward Of Level</th>
            <th nzWidth="80px">Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let data of editRowTable.data; let i = index;" class="editable-row">
            <td>
                {{i+1}}
            </td>
            <td>
                <input
                    nz-input
                    [(ngModel)]="data.description"
                />
            </td>
            <td>
                <mit-nz-uploader [prefix]="i+1"></mit-nz-uploader>
            </td>
            <td>
                <nz-select class="grid-select" [(ngModel)]="data.mmitaRewardIdOfKeys">
                    <nz-option *ngFor="let item of rewardSource" [nzValue]="item.id" [nzLabel]="item.description"></nz-option>
                </nz-select>
            </td>
            <td>
                <nz-select class="grid-select" [(ngModel)]="data.mmitaRewardIdOfLevel">
                    <nz-option *ngFor="let item of rewardSource" [nzValue]="item.id" [nzLabel]="item.description"></nz-option>
                </nz-select>
            </td>
            <td>
            <i class="fas fa-fw fa-trash text-danger" (click)="deleteRow(i)" style="margin-right: 5px; cursor: pointer;"></i>
            <i class="fas fa-fw fa-bars" (click)="showDetail(data)" style="cursor: pointer;"></i>
            </td>
        </tr>
        </tbody>
    </nz-table>

    <nz-divider></nz-divider>

    <div style="text-align: center;">
        <button nz-button nzType="primary" nzSize="large" nzShape="round" (click)="submitForm()">
            Save
        </button>
    </div>

</div>

