import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { InfiniteResult, MitFieldInfo } from 'src/app/_models/body';
import { CustomerService } from 'src/app/_services/customer.service';
import { EscapeGameService } from 'src/app/_services/escape-game.service';
import { InputFieldType } from 'src/app/common/constants';

@Component({
    selector: 'app-game-white-list-dialog',
    templateUrl: '../white-list-dialog/white-list-dialog.component.html',
})
export class GameWhiteListDialogComponent implements OnInit {
  formGroup: FormGroup
  dialogFields: Array<MitFieldInfo>
  callback = ()=> this.onSubmit()
  currentOptions: Array<any>

  constructor(
    private customerService: CustomerService,
    private escapeGameService: EscapeGameService,
    private toastr: ToastrService,
    private nzModalRef: NzModalRef
  ) { 

  }


  ngOnInit() {
    this.formGroup = new FormGroup({
      userId: new FormControl(null, Validators.required),
    });

    this.dialogFields = [
        {
            key: 'userId',
            label: 'User ID',
            type: InputFieldType.Selector,
            size: 24,
            required: true,
            selectorLabel: 'email',
            selectorSearchEvent: (ev)=>this.getActiveCustomer(ev),
            options: this.currentOptions
        }
      ]
  }


  onSubmit(){
    return new Observable(sub=>{
      this.escapeGameService.createGameWhiteList(this.formGroup.value).subscribe(data => {
        this.toastr.success(`Saved Successfully...!`, 'Success');
        this.nzModalRef.triggerOk()
        sub.next()
        sub.complete()
      });
    })

  }


  getActiveCustomer(value?){
    return new Observable(subscriber => {
      const prop = value ? {Email_Contains: value} : null
      this.customerService.getAll({PageSize: 100, IsActive:true, ...prop}).subscribe((res: InfiniteResult)=>{
        this.currentOptions = res.data
        subscriber.next(res.data)
        subscriber.complete()
      });
    })
  }
}

