    <section class="header-btn-wrapper">
      <button nz-button nzType="primary" (click)="addWhiteList()">
        <i class="fas fa-fw fa-plus"></i>
         Add Whitelist
        </button>
       
    </section>
    <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="whiteListData"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    (gridReady)="onGridReady($event)"
    [enableCellTextSelection]="true"
  >
  </ag-grid-angular>
  
  