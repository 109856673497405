import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfiniteResult } from '../_models/body';

@Injectable({
  providedIn: 'root'
})
export class EscapeGameService {
    httpOptions: any = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    constructor(private http: HttpClient) { }

    getPortal(data) {
      return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/Query`, {
        params: data
      });
    }

    createPortal(data) { 
        return this.http.post<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal`, data);
    }

    updatePortal(data, portalId) { 
        return this.http.put<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/${portalId}`, data);
    }

    deletePortal(portalId) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portal/${portalId}`);
    }

    batchDeletePortal(ids) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/Portals`, {
            body: {
                ids: ids
            }
        })
    }

    getEscapeGameList(params?) { 
        return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/AstralEscapeGame/Query`, {
            params: {
                PageSize: -1,
                ...params
          }
      })  
    }

    deleteGame(id: string) { 
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/${id}`) 
    }

    updateGame(data, id) {
        return this.http.put<any>(`${environment.apiEndpoint}api/AstralEscapeGame/${id}`, data);
    }

    createGame(data) { 
        return this.http.post<any>(`${environment.apiEndpoint}api/AstralEscapeGame`, data);
    }

    releaseGame(id) { 
        return this.http.put<any>(`${environment.apiEndpoint}api/AstralEscapeGame/${id}/Release`, null);
    }

    takeDownGame(id) { 
        return this.http.put<any>(`${environment.apiEndpoint}api/AstralEscapeGame/${id}/TakeDown`, null);
    }

    getGameWhiteList(params) { 
        return this.http.get<InfiniteResult>(`${environment.apiEndpoint}api/AstralEscapeGame/WhiteList/Query`, {
            params: params
        }) 
    }

    createGameWhiteList(data) { 
        return this.http.post<InfiniteResult>(`${environment.apiEndpoint}api/AstralEscapeGame/WhiteList`, data) 
    }

    deleteWhiteList(id) {
        return this.http.delete<any>(`${environment.apiEndpoint}api/AstralEscapeGame/WhiteList/${id}`) 
    }
}
