<section class="header-btn-wrapper">
    <button nz-button nzType="primary" (click)="addWhiteList()">
      <i class="fas fa-fw fa-plus"></i>
       Add Whitelist
      </button>
  </section>
<ag-grid-angular
#agGrid
class="ag-theme-alpine"
[gridOptions]="gridOptions"
[columnDefs]="columnDefs"
[defaultColDef]="defaultColDef"
[suppressRowClickSelection]="true"
[enableCellTextSelection]="true"
(gridReady)="onGridReady($event)"
style="height: 80vh"
[pagination]="true"
(sortChanged)="sortChanged($event)"
>
</ag-grid-angular>
