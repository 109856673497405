import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Global } from 'src/app/_services/global.service';
import { ModalService } from 'src/app/_services/modal.service';
import { BaseComponent } from 'src/app/ui-library/component/base.component';
import { EscapeGameService } from 'src/app/_services/escape-game.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EscapeGameSettingComponent } from '../escape-game-setting/escape-game-setting.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmComponent } from 'src/app/ui-library/dialog/confirm/confirm.component';

@Component({
  selector: 'app-escape-game-list',
  templateUrl: './escape-game-list.component.html',
  styleUrls: ['./escape-game-list.component.css']
})
export class EscapeGameListComponent extends BaseComponent implements OnInit {
    data: Array<any>;
    columnDefs = [];
    formGroup: FormGroup
    constructor(
        private escapeGameService: EscapeGameService,
        public global: Global,
        public modalService: ModalService,
        private toastr: ToastrService,
        private nzModalService: NzModalService,
        private ngbModal: NgbModal
    ){
      super(global);
    }


    setColumnDefs(){
      this.columnDefs = [
          { headerName: 'ID', field: 'id' },
          { headerName: 'Description', field: 'description'},
          {
            headerName: 'Thumbnail', field: 'thumbnail',
            cellClass: 'img-cell',minWidth: 130,
            cellRenderer: params => {
              return ` <img height="80" src="${params.value}?m=${Math.random()}" alt="without thumbnail"/>`;
            },
          },
          { headerName: 'Released', field: 'isReleased'},
          
      
        {
          headerName: 'Action',
          pinned: 'right',
          cellRenderer: params => {
            return ` 
                      <i class="fas fa-fw fa-bars" id="moreDetail" style="margin-right:3px; cursor: pointer;"></i>
                      ${params.data.isReleased? 
                        ` <i class="fas fa-fw fa-cloud-arrow-down text-secondary" id="takeDown" style="margin-right:3px; cursor: pointer;"></i>`
                        : 
                      `
                      <i class="fas fa-fw fa-cloud-arrow-up text-success" id="release" style="margin-right:3px; cursor: pointer;"></i>`}
                      <i class="fas fa-fw fa-edit text-primary" id="editDetail" style="margin-right:3px; cursor: pointer;"></i>
                      <i class="fas fa-fw fa-trash grid-btn-icon text-danger" id="delete" style="margin-right:3px; cursor: pointer;"></i>`;
          },
          onCellClicked: params => {
            switch(params.event.target.id){
                case 'editDetail':
                    this.showItem(params?.data)
                    break;
                case 'delete':
                    this.modalService.createNzDeleteModal(()=>this.delete.call(this, params?.data?.id))
                    break;
                case 'moreDetail':
                    this.modalService.showDetail(params.data, 'Game Detail')
                        break;
                case 'release':
                case 'takeDown':
                    this.showDialog(params.event.target.id, params)
                    break;
                }
          },
          minWidth: 120
        }
    ];
    }

    showDialog(action: string,params){
        let api;
        const modal = this.ngbModal.open(ConfirmComponent, { size: 'sm' })
        const msg = action === 'release' ? `Confirm to ${action}? If you release the current game, other released games will be taken down` : `Confirm to ${action}?`
        modal.componentInstance.confirmMessage = msg
        modal.result.then(res=>{
        if(!res){
          return
        }
        switch(action){
          case 'release':
            api = (id)=>this.escapeGameService.releaseGame(id)
            break;
          case 'takeDown':
            api = (id)=>this.escapeGameService.takeDownGame(id)
            break;
        }
        
        api(params.data.id).subscribe(res=>{
                  this.toastr.success(`${action} successfully`);
                  this.getAll()
                })
        }).catch()
      }

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.getAll();
    }
    
    ngOnInit(){
       this.setColumnDefs();
    }

    getAll(){
        this.escapeGameService.getEscapeGameList().subscribe(res=>{
            this.data = res.data;
        })
    }

    delete(id){
        this.escapeGameService.deleteGame(id)
        .subscribe(data => {
            this.getAll();
            this.toastr.success('Data Deleted Successfully...!', 'Success');
        });
    }

    showItem(data?){
        this.nzModalService.create({
            nzContent: EscapeGameSettingComponent,
            nzWidth: '1400px',
            nzFooter: null,
            nzTitle: 'Set Game',
            nzComponentParams: {
              id: data?.id
            },
            nzOnOk: ()=>{
                this.toastr.success('Saved Successfully...!', 'Success');
                this.getAll()
              },
          })
    }

}
