import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, of } from 'rxjs';
import { CategoryService } from 'src/app/_services/category.service';
import { EscapeGameService } from 'src/app/_services/escape-game.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { MmitaAssetService } from 'src/app/_services/mmitaAsset.service';
import { ModalService } from 'src/app/_services/modal.service';
import { RewardService } from 'src/app/_services/reward.service';
import { IntervalType } from 'src/app/common/constants';
import { MitNzUploaderComponent } from 'src/app/ui-library/mit-nz-uploader/mit-nz-uploader.component';
import { cloneDeep } from 'lodash'
import { NzModalRef } from 'ng-zorro-antd/modal';

interface Level {
    levelNumber?: number;
    settingJsonUrls: Array<string>;
    mmitaRewardIdOfKeys: string;
    mmitaRewardIdOfLevel: string;
    description: string
}

@Component({
    selector: 'app-escape-game-setting',
    templateUrl: './escape-game-setting.component.html',
    styleUrls: ['./escape-game-setting.component.scss']
})
export class EscapeGameSettingComponent implements OnInit {
    @ViewChildren(MitNzUploaderComponent) uploaderComponents!: QueryList<MitNzUploaderComponent>;
    readonly IntervalType = IntervalType;
    id: string
    formGroup: FormGroup;
    date: any;
    categorySource: Array<any>;
    assetSource: Array<any>;
    rewardSource: Array<any>;
    listOfData: Level[] = [];
    constructor(
        private escapeGameService: EscapeGameService,
        private categoryService: CategoryService,
        private assetService: MmitaAssetService,
        private rewardService: RewardService,
        private fileUploadService: FileUploadService,
        private modalService: ModalService,
        private nzModalRef: NzModalRef
    ) { }

    initPage() {
        const escapeGameObservable = this.id ? this.escapeGameService.getEscapeGameList({ Id: this.id }) : of(null)
        forkJoin([
            escapeGameObservable,
            this.categoryService.getAssetCategory(),
            this.rewardService.getAll()
        ]).subscribe(([escapeGameList, categoryRes, rewardRes]) => {
            const settingRes = escapeGameList?.data[0]
            this.categorySource = categoryRes.data;
            this.rewardSource = rewardRes.data;

            this.formGroup = new FormGroup({
                assetCategoryId: new FormControl(settingRes?.assetCategoryId),
                description: new FormControl(settingRes?.description),
                _date: new FormControl([new Date(settingRes?.startAt || new Date()), new Date(settingRes?.endAt || new Date())]),
                thumbnail: new FormControl(settingRes?.thumbnail),
                videoUrl: new FormControl(settingRes?.videoUrl),
                keysAssetId: new FormControl(settingRes?.keysAssetId),
                portalAssetId: new FormControl(settingRes?.portalAssetId),
                mmitaRewardIdOfAllKeys: new FormControl(settingRes?.mmitaRewardIdOfAllKeys),
                mmitaRewardIdOfAllLevels: new FormControl(settingRes?.mmitaRewardIdOfAllLevels),
                coolDownIntervalType: new FormControl(settingRes?.coolDownIntervalType),
                tutorialSettingJsonUrl: new FormControl(settingRes?.tutorialSettingJsonUrl),
                isAllowRescue: new FormControl(settingRes?.isAllowRescue || false),
                popPic: new FormControl(settingRes?.popPic),
                puzzleSettingUrl: new FormControl(settingRes?.puzzleSettingUrl),
                scale: new FormControl(settingRes?.scale || 1),
            });

            if (settingRes?.assetCategoryId) {
                this.setAssetSource(settingRes?.assetCategoryId)
            }
            this.listOfData = settingRes?.levels
        });
    }

    ngOnInit(): void {
        this.initPage()
    }

    setAssetSource(ev) {
        this.assetService.getAll({ CategoryId: ev, PageSize: -1 }).subscribe(res => {
            this.assetSource = res.data
        })
    }

    addRow() {
        const newRow: Level = {
            settingJsonUrls: [],
            mmitaRewardIdOfKeys: '',
            mmitaRewardIdOfLevel: '',
            description: '',
        };
        this.listOfData = this.listOfData ? [...this.listOfData, newRow] : [newRow];

    }

    deleteRow(index: number) {
        this.listOfData?.splice(index, 1);
        this.listOfData = [...this.listOfData];
    }


    submitForm() {
        const setOrAddControl = (formGroup: FormGroup, controlName: string, value: any) => {
            const control = formGroup.get(controlName);
            if (control) {
                control.patchValue(value);
            } else {
                formGroup.addControl(controlName, new FormControl(value));
            }
        }

        const saveSetting = (listOfData, thumbnail?, videoUrl?, tutorialSettingJsonUrl?, popPic?, puzzleSettingUrl?) => {
            const _dateRange = this.formGroup.value._date;
            setOrAddControl(this.formGroup, 'startAt', new Date(_dateRange[0]).toISOString());
            setOrAddControl(this.formGroup, 'endAt', new Date(_dateRange[1]).toISOString());
            setOrAddControl(this.formGroup, 'levels', listOfData);

            thumbnail && this.formGroup.get('thumbnail').patchValue(thumbnail?.origin)
            videoUrl && this.formGroup.get('videoUrl').patchValue(videoUrl?.origin)
            tutorialSettingJsonUrl && this.formGroup.get('tutorialSettingJsonUrl').patchValue(tutorialSettingJsonUrl?.origin)
            popPic && this.formGroup.get('popPic').patchValue(popPic?.origin)
            puzzleSettingUrl && this.formGroup.get('puzzleSettingUrl').patchValue(puzzleSettingUrl?.origin)

            const data = cloneDeep(this.formGroup.value);
            // delete data._date
            const api = this.id ? this.escapeGameService.updateGame(data, this.id) : this.escapeGameService.createGame(data)
            api.subscribe(
                {
                    next: res => {
                        this.nzModalRef.triggerOk()
                    }, error: err => {
                        // this.initPage()
                    },
                });
        }

        const getAllFileLists = () => {
            return this.uploaderComponents.reduce((acc, uploaderComponent) => {
                return [...acc, ...uploaderComponent.getFileList()];
            }, []);
        };

        const getFileFromField = (fieldName) => {
            if (Array.isArray(this.formGroup.value[fieldName]) && this.formGroup.value[fieldName].length > 0) {
                return { ...this.formGroup.value[fieldName][0], name: this.formGroup.value[fieldName][0].guid };
            }
            return null;
        };

        const allFileLists = [
            ...getAllFileLists(),
            ...(getFileFromField('thumbnail') ? [getFileFromField('thumbnail')] : []),
            ...(getFileFromField('videoUrl') ? [getFileFromField('videoUrl')] : []),
            ...(getFileFromField('tutorialSettingJsonUrl') ? [getFileFromField('tutorialSettingJsonUrl')] : []),
            ...(getFileFromField('popPic') ? [getFileFromField('popPic')] : []),
            ...(getFileFromField('puzzleSettingUrl') ? [getFileFromField('puzzleSettingUrl')] : [])
        ];

        const buildLevel = (res) => {
            const categorizeUploadedByName = (arr) => {
                const result = {};
                arr.forEach(item => {
                    const nameValue = item.name;
                    const prefix = nameValue.split('-')[0];
                    if (!result[prefix]) {
                        result[prefix] = [];
                    }
                    result[prefix].push(item?.origin);
                });

                return result;
            }

            const formattedData = categorizeUploadedByName(res);
            return this.listOfData?.map((item, index) => {
                return {
                    mmitaRewardIdOfKeys: item.mmitaRewardIdOfKeys,
                    mmitaRewardIdOfLevel: item.mmitaRewardIdOfLevel,
                    levelNumber: index + 1,
                    settingJsonUrls: formattedData[(index + 1).toString()] ?? item.settingJsonUrls,
                    description: item.description
                }
            })
        }

        const getFileUrl = (res: Array<any>, fieldName: string) => {
            if (!Array.isArray(this.formGroup.value[fieldName])) {
                return null;
            }
            return res.find(item => item.name === this.formGroup.value[fieldName][0].guid);
        };


        if (allFileLists.length > 0) {
            this.fileUploadService.postFile(allFileLists).subscribe(res => {
                const listOfData = buildLevel(res)
                saveSetting(
                    listOfData, 
                    getFileUrl(res, 'thumbnail'), 
                    getFileUrl(res, 'videoUrl'), 
                    getFileUrl(res, 'tutorialSettingJsonUrl'), 
                    getFileUrl(res, 'popPic'), 
                    getFileUrl(res, 'puzzleSettingUrl'))
            })
        } else {
            const listOfData: Array<Level> = this.listOfData?.map((item, index) => {
                return {
                    mmitaRewardIdOfKeys: item.mmitaRewardIdOfKeys,
                    mmitaRewardIdOfLevel: item.mmitaRewardIdOfLevel,
                    levelNumber: index + 1,
                    settingJsonUrls: item.settingJsonUrls,
                    description: item.description
                }
            })
            saveSetting(listOfData)
        }
    }

    showDetail(data) {
        this.modalService.showDetail(data, 'Level Scenes Detail')
    }




}
