<section class="header-btn-wrapper">
    <button type="button" (click)="showItem()" type="button" nz-button nzType="primary" style="margin-right: 5px;">
      <i class="fas fa-fw fa-plus"></i> Add Game
    </button>
  </section>
  <ag-grid-angular
    #agGrid
    domLayout='autoHeight'
    class="ag-theme-alpine"
    [rowData]="data"
    [columnDefs]="columnDefs"
    rowSelection="multiple"
    [defaultColDef]="defaultColDef"
    [pagination]="true"
    [suppressRowClickSelection]="true"
    [enableCellTextSelection]="true"
    (gridReady)="onGridReady($event)"
    [rowHeight]="80"
  >
  </ag-grid-angular>